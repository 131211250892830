input[type=checkbox], input[type=radio] {
    //Resets
    width: auto;
    border: 0;
    padding: 0;
    margin: 0;
    display: inline-block;
    cursor: pointer;

    margin-right: $unit;
}

input[type=checkbox]:checked + label::after {
    content: '';
    position: absolute;
    width: 1.2ex;
    height: 0.4ex;
    @include color(background-color, 'body');
    top: 0.9ex;
    left: 0.4ex;
    border: 2px solid blue;
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
 }

 input[type=checkbox] {
    line-height: 2.1ex;
 }

 //input[type=radio],
 //nput[type=checkbox] {
 //    position: absolute;
  //   left: -999em;
 //}

 input[type=checkbox] + label {
     position: relative;
     overflow: hidden;
     cursor: pointer;
 }

 input[type=checkbox] + label::before {
    content: "";
    display: inline-block;
    vertical-align: -25%;
    height: 2ex;
    width: 2ex;
    @include color(background-color, 'body');
    border: 1px solid rgb(166, 166, 166);
    border-radius: 4px;
    box-shadow: inset 0 2px 5px rgba(0,0,0,0.25);
    margin-right: 0.5em;
 }

.checkbox{
    position: relative;
    input{
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
}

 /* Create a custom checkbox */
.checkmark {
    position: absolute;
    //top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    @include color(background-color, 'body');
    border-radius: 2px;
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox:hover input ~ .checkmark {
    @include color(background-color, 'body');
    border-radius: 2px;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox input:checked ~ .checkmark {
    @include color(background-color, 'body');
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

span.wpcf7-list-item {

    &.first {
        margin-left: 0;
    }
    
    .wpcf7-list-item-label {
        margin-right: $unit * 2;
    }
}

input[type=checkbox] {
    appearance: checkbox;
}

input[type=radio] {
    appearance: radio;
}

.wpcf7-checkbox {
    text-align: left;
}
.checkbox{
    

        @include size-XS;
        font-family: $font-stack-primary;
        color: rgba(255, 255, 255, 0.5);
}