//Form Styling

form {
    //Set vertical rhythm for all child elements of a form
    > * {
        margin-bottom: 1.5rem;

        &.no-margin {
            margin-bottom: 0;
        }
        //Remove margin-bottom for last element
        &:last-child {
            margin-bottom: 0;
        }
    }
    .row {
        display: flex;
        margin-left: -1rem;
        margin-right: -1rem;

        @include breakpoint(XS) {
            flex-direction: column;
            margin: 0;
        }
    }
    .full-width {
        padding: 0 1rem;
        width: 100%;

        @include breakpoint(XS) {
            padding: 0;
            margin-bottom: 1rem;
        }
    }
    .half {
     
        margin: 0;
        

        @include breakpoint(XS) {
            width: 100%;
            padding: 0;
            margin-bottom: 1rem;
        }
    }

    input[type="date"]::before {
        content: attr(placeholder);
        position: absolute;
        color: #666666;
      }

      input[type="date"] {
        color: rgba(0, 0, 0, 0);
      }
      

    input[type="date"]:focus {
        color: #666666;
    }

    input[type="date"]:focus::before {
    content: "";
    }

    ::-webkit-calendar-picker-indicator {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='22' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 3H1V21H19V3H16M6 3H9M11 3H14M1 6.625L19 6.625M5 5C4.44772 5 4 4.55228 4 4V2C4 1.44772 4.44772 1 5 1C5.55228 1 6 1.44772 6 2V4C6 4.55228 5.55228 5 5 5ZM10 5C9.44772 5 9 4.55228 9 4V2C9 1.44772 9.44772 1 10 1C10.5523 1 11 1.44772 11 2V4C11 4.55228 10.5523 5 10 5ZM15 5C14.4477 5 14 4.55228 14 4V2C14 1.44772 14.4477 1 15 1C15.5523 1 16 1.44772 16 2V4C16 4.55228 15.5523 5 15 5ZM4 10H5.5V11.5H4V10ZM4 13.5H5.5V15H4V13.5ZM7.5 10H9V11.5H7.5V10ZM7.5 13.5H9V15H7.5V13.5ZM11 10H12.5V11.5H11V10ZM11 13.5H12.5V15H11V13.5ZM14.5 10H16V11.5H14.5V10ZM14.5 13.5H16V15H14.5V13.5ZM4 17H5.5V18.5H4V17ZM7.5 17H9V18.5H7.5V17ZM11 17H12.5V18.5H11V17ZM14.5 17H16V18.5H14.5V17Z' stroke='%23C6BAAF' stroke-width='0.75'/%3E%3C/svg%3E");
        width: 3rem;
        height: 3rem;
    }

     input[type=checkbox] {
        font-size: 0; 
        line-height:0; 
        }
    
    .wpcf7-list-item{
        margin-bottom: 1rem;
    }

    .dropdown {
        &::after {
            display: none;

            @media screen and (max-width: 1024px) {
                display: block;
                position: absolute;
                content: "";
                right: 1.111rem;
                z-index: 2;
                top: calc(50% - 0.625rem);
                background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"%3E%3Ctitle%3Ecaret-down%3C/title%3E%3Cg class="caret-down"%3E%3Cpolyline class="arrowhead" points="36.036 18.982 24 31.018 11.964 18.982" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/%3E%3C/g%3E%3C/svg%3E');
                background-repeat: no-repeat;
                width: 1.25rem;
                height: 1.25rem;
            }
        }
    }
}

.select2-results__option {
    &::before{
        display: none;
    }
}

.select2-search--dropdown{
    background: var(--color-accent-platinum, #EBEAE6) !important;
}

.select2-results__options{
    background: var(--color-accent-platinum, #EBEAE6) !important;
}


.select2-selection--single {
    border: none !important;
    background: none !important;
}

.select2-selection__arrow{
    // display: none !important;

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.select2-results__option--highlighted{
    background: var(--color-accent-primary, #513F3D) !important;
}

.select2-dropdown--below{
    border-top: 1px solid #513F3D !important;
}
.select2-dropdown{
    @include breakpoint(M){
        width: calc(75vw - 4rem) !important;
    }
}

.selection {
    font-family: $font-stack-primary;
}

//Label Styling
label {
    font-weight: 400;
    @include size-XL;
    font-family: $font-stack-headings;
    @extend %transition;
    @include color(color, 'body');
    line-height: 0.5rem !important;

    //Apply margin between labels and inputs
    input,
    textarea,
    .dropdown {
        margin-top: $unit;
    } 

    //Active styling
    &.active {
        @include color(color, 'accent-primary');
    }
}

//Standard Input Styling

input,
textarea,
.dropdown {
    //Resets
    display: block;
    box-shadow: none;
    -webkit-appearance: none;
    position: relative;
    z-index: 2;
    background:transparent;
    color: $color-body;
    border-style: unset;
    border-bottom: solid 1px;
    @include color(border-color, 'body');

    font-family: $font-stack-headings;
    @include size-M;

    padding: 0.888rem;
    padding-left:0;
    width: 100%;

    @extend %transition;

    //Focus State
    &:focus,
    &:hover {
        @include color(border-color, 'accent-primary');
        outline: 0;
    }

    @include placeholder {
        @include color(color, 'body');
        opacity: 0.5;
    }
    &[type=text] {
        appearance: none;
    }
    &.wpcf7-not-valid {
        @include color(color, 'error');
    }
    @include breakpoint(XS) {
        font-size: 16px;
    }
}

.input-wrap,
.dropdown {
    position: relative;

    label {
        z-index: 2;
        position: absolute;
        top: 0.5rem;
        left: calc(0.888rem + 2px);
    }
    input,
    textarea {
        padding: 1.5rem 0.888rem 0.5rem 0.888rem;
        margin: 0;

        &:focus {
            outline: 0;

            + label {
                @include color(color, 'accent-primary');
            }
        }
    }
    textarea {
        height: 10rem;
    }
    &.label-active {
        label {
            @include color(color, 'accent-primary');
        }
    }
    &.datepicker {
        cursor: pointer;
        border-radius: 0;

        input {
            cursor: pointer;
        }
    }
}

.wpcf7 {
    padding-top: 3.5rem;

    form div.wpcf7-response-output {
        margin: 0 0 2rem 0;
        border: 0;
        clear: both;
        @include color(color, 'background');
        padding: 1rem;
        @include color(background-color, 'accent-primary');
        position: relative;
        text-align: center;

        &.wpcf7-validation-errors {
            @include color(background-color, 'error');
        }
    }
    input[type="submit"] {
        margin-top: 2rem;
    }
}

.wpcf7-not-valid-tip {
    @include size-S;
}

.ajax-loader {
    display: block;
}

div.wpcf7 .ajax-loader {
    display: block;
    margin: 0 auto;
}
.acf-field .acf-label label{
    font-family: $font-stack-primary!important;
    @include size-S;
}

@import "dropdowns";
@import "checkboxes";
@import "textarea";