.signup-box{
    background-color: transparent;
  
    @include breakpoint(S){
        //padding-top: 1.9375rem;
        //padding-bottom: 1.9375rem;
       
        
    }
    .callout-box{
        display: flex;
        justify-content: center;
        @include color(background-color, 'accent-pale-silver');
        padding-top:4.5rem;
        padding-bottom: 4.5rem;
        @include breakpoint(S){
            padding-left:3rem;
            padding-right:3rem;
        }
        
        h1{
            @include size-3XL;
            display: flex;
            align-items: center;
           
            
        }
        .icon{
            @include color(border-color, 'body');
            margin-left: 1.5rem;
            margin-bottom: 0;
            svg{
                color:white;
            }
        }
    }
    .button.icon.close-class{
        display: none;
    }
    .newsletter-box{
        display: none;
        padding-left:13.9375rem;
        padding-right: 13.9375rem;
        //margin-top: 3.0625rem;
        @include breakpoint(S){
            padding-left:3rem;
            padding-right:3rem;
        }
        .form-wrapper{
            display: grid;
            gap:4.5rem;
            grid-template-columns: auto auto auto;
            @include breakpoint(S){
                grid-template-columns: unset;
            }
            .form-input{

            }
        }

        .submit-wrapper {
            margin-top: 4.25rem;
            gap: 1.5rem;

            @include breakpoint(XS) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
        input[type="submit"]{
            // margin: 0 auto;
            text-align: center;
            display: flex;
            // margin-top: 4.25rem;
            margin-bottom: 0;
        }
        .checkbox-wrap{
            margin: 0 auto;
            display: flex;
            justify-content: center;
        }
        .checkbox{
            text-align: center;
            justify-content: center;
            padding-top: 2.125rem;
            padding-left:3rem;
            @include breakpoint(S){
             text-align: left;
            }
        }
        .checkmark{
            top: 2rem;
        }
    }

    @keyframes fadeInUpNews {
        0% {
           opacity: 0;
           transform: translateY(-10rem);
           height:0%;
        }
        100% {
           opacity: 1;
           transform: translateY(0);
           height:100%;
        }
    }

    @keyframes fadeInDownNews {
        0% {
           height: auto;
           
        }
        100% {
           
           
        }
    }

    &.opened{
        
        padding-bottom: 0rem;
        .button.icon.open-class{
            display: none;
        }
        .button.icon.close-class{
            display: flex;
        }
        .newsletter-box{
            display: block;
            z-index: 0;
            position: relative;
            animation: fadeInUpNews 1.0s ease-out both;
            @include color(background-color, 'accent-pale-silver');
            padding-bottom: 4.5rem;
        }
    }
}
.footer {
    padding-top: 2rem;
    padding-bottom: 2rem;
    @include color(background-color, 'accent-platinum');
    .social-links {
        width: 5%;
        flex-shrink: 0;

        @include breakpoint(S) {
            gap: 1rem;
        }

        @include breakpoint(XS) {
            width: 100%;
        }
        .button {
            margin: 0 0 1rem 0;

            @include breakpoint(XS) {
                margin: 0 0.5rem;
            }
        }
    }
}

.footer-logo {
    width: 25%;
    padding-right: 2rem;

    @include breakpoint(S) {
        width: 100%;
        padding-right: 0;
    }
    svg {
        max-width: 100%;
        fill:#513F3D;

        @include breakpoint(S) {
            width: 8rem;
            height: auto;
        }
        @include breakpoint(XS) {
            width: 9rem;
        }
    }
    svg > *{
        fill:#513F3D;

    }
}

.footer-content {
    width: 70%;

    @include breakpoint(S) {
        width: 90%;
    }
    @include breakpoint(XS) {
        width: 100%;
    }
    .footer-menu {
        @include breakpoint(XS) {
            width: 100%;
        }
    }
}

.footer-menu {
    margin-bottom: 2rem;
    padding-right: 1rem;

    // Check if browser supports grid (IE doesn't support support queries themselves so it'll ignore everything in here)
    @supports (display: grid) {
        padding-right: 0;
        width: 100%;
    }
    @include breakpoint(XS) {
        padding-right: 0;
        margin-bottom: 1.5rem;
       // border-bottom: 1px solid;
        @include color(border-color, 'body-10');
    }
    .footer-menu-wrap {
        @include breakpoint(XS-up) {
            display: block !important; // this is in case someone closes it on mobile and goes to a larger screen
        }
        @include breakpoint(XS) {
          //  display: none;
            padding-bottom: 0.5rem;
        }
    }
    ul {
        width: 100%;
        display: grid;
        // Make a 2 column grid where the columns have an auto min-width but a max-width of 18rem so they don't stretch ridiculously far
        grid-template-columns: repeat(4, minmax(auto, 16rem));
        @include breakpoint(S){
            grid-template-columns: auto auto;
            row-gap: 0.875rem;
        }
        column-gap: 2rem;
        row-gap: 1rem;
        padding-right: 1rem;

        @supports (display: grid) {
            margin-bottom: 0;
            padding-right: 0;
        }
        li {
            margin-bottom: 1rem;
            padding-right: 1rem;

            @supports (display: grid) {
                margin-bottom: 0;
                padding-right: 0;
            }
            @include breakpoint(XS) {
                margin-bottom: 0;
            }
        }
    }
    a {
        display: block;
        @include color(color, 'body');
        @include size-S;

        @include breakpoint(XS) {
            padding: 0rem 0;
        }
        &:hover {
            @include color(color, 'accent-timberwolf');
        }
    }
}

.mob-footer-menu-toggle {
    width: 100%;

    .button.icon {
        display: none;

        @include breakpoint(XS) {
            display: flex;
        }
    }
    @include breakpoint(XS) {
        @include size-L;

        &.active {
            @include color(color, 'accent-primary');

            .button {
                @include color(border-color, 'accent-primary');

                svg {
                    @include color(color, 'accent-primary');
                }
            }
        }
    }
}

.footer-content-top {
    display: grid;
    // Basically this is saying "if there's enough space, fill in columns at comfortable width with a min-width of 16rem
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    gap: 2rem;
}

.footer-content-bottom {
    @include breakpoint(XS) {
        padding-top: 1.5rem;
        text-align: left;
    }
    span {
        margin-right: 2rem;

        @include breakpoint(S) {
            margin: 0 0 1.5rem 0;
            width: 100%;

            &:last-child {
                margin-bottom: 3rem;
            }
        }
    }
    a {
        @include color(color, 'body');

        &:hover {
            @include color(color, 'accent-primary');
        }
    }
}