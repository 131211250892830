// Fonts for UP Core
$font-stack-primary: 'DM Sans', Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', Arial, sans-serif;
$font-stack-headings: 'Regatto', 'Iowan Old Style', 'Palatino Linotype', 'URW Palladio L', P052, serif;
$font-stack-headings-secondary: 'Playfair Display', Didot, 'Bodoni MT', 'Noto Serif Display', 'URW Palladio L', P052, Sylfaen, serif;


// General Breakpoints
$breakpoints: (
    'XXS': ( max-width:  30em ),
    'XS': ( max-width:  40em ),
    'XS-up': ( min-width:  40.001em ),
    'S': ( max-width:  48em ),
    'S-up': ( min-width: 48.001em ),
    'SM': ( max-width:  62.5em ),
    'M': ( max-width:  64em ),
    'M-up': ( min-width:  64em ),
    'ML': ( max-width:  75em ),
    'ML-up': ( min-width:  75em ),
    'L': ( max-width: 91em ),
    'XL': ( min-width: 91em ),
    'XXL': ( min-width: 105em ),
    'XXXL': ( min-width: 112em )
);
