.blog-single-banner{
    display: flex;
    justify-content: normal;
    .banner-content-block{
        text-align: left;
        .button.icon-left.back{
            padding-left:0;
        }
        .share-tags{
            display: flex;
            align-items: baseline;
            @include breakpoint(S){
                flex-direction: column;
            }
            a::after{
            content: ",";
            padding-right: 0.25rem;
            }
            .post-share{
                //padding-left:2rem;
                @include breakpoint(S){
                    padding-left: 0rem;
                }
                strong{
                    font-family: $font-stack-headings;
                    font-weight: normal;
                }
            }
        }
    }
}
@keyframes fadeInUpArticle {
    0% {
       opacity: 0;
       transform: translateY(2rem);
    }
    100% {
       opacity: 1;
       transform: translateY(0);
    }
  }
  
  .view-article-button {
    animation: fadeInUpArticle 0.3s ease-out both;
  }

  .view-article-button{
    display: none;
    
  }



.blog-background-part{
    width: 100%;
    position: absolute;
    @include color(background-color, 'accent-alabaster');
    height: 55rem;
    z-index: -1;
}
.banner-top-large-text{
    height:8rem;
    width:100%;
    @include color(background-color, 'accent-alabaster');
    margin-top:20.75rem;

    @include breakpoint(S){
        margin-top:10rem;
        height:2rem;
    }
    .main-title-top-blog{
    
        top: -5rem;
        position: relative;
        width: 100%;
        @include breakpoint(M) {
            top: -12rem;
        }
        h1{
            font-family: Regatto;
            font-style: normal;
            font-weight: normal;
            font-size: 120px;
            line-height: 158px;
            @include color(color, 'accent-pale-silver');
            text-align: center;
        }
        @include breakpoint(S){
            top: -1.5rem;
            h1{
            
                @include size-3XL;

            }
        }
    }
    
}
.background-alabaster{
    @include color(background-color, 'accent-alabaster');
}
.subtitle{
    letter-spacing: 0.125em;
    text-transform: uppercase;
    font-weight: bold;
    @include size-S;
}



.single-post {
    .banner-block {
        .banner-content-block{
            padding-top: 10rem;
        }
        h1 {
            .subtitle {
                margin-top: 0.5rem;
            }
        }
    }
}
.excerpt-style{
    font-family: $font-stack-headings;
}
.page-container.blog-post{
    p{
        font-family: $font-stack-primary;
    }
    @include breakpoint(S){
        margin-left:0!important;
    }
}
.blog-post {
    p,
    li {
        font-family: $font-stack-headings;
        max-width: 50rem;

        @include breakpoint(S) {
            max-width: 100%;
            padding-left:1.75rem;
            padding-right:1.75rem;
        }
    }
    p {
        margin-bottom: 1.75rem;
    }
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        margin-bottom: 2rem;
        max-width: 50rem;

        @include breakpoint(S) {
            max-width: 100%;
            padding-left:1.75rem;
            padding-right:1.75rem;
            
        }
    }
    h2 {
        margin-top: 3rem;
    }
    h3,
    h4 {
        margin-top: 1.75rem;
    }
    blockquote {
        margin-top: 6rem;
    }
    blockquote,
    .wp-block-embed,
    ul,
    ol,
    hr {
        margin-bottom: 1.75rem;
        max-width: 50rem;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(S) {
            max-width: calc(100vw - 4rem);
        }
    }
    .wp-block-image {
        figcaption {
            margin-top: 0;
            text-align: left;
            font-family: $font-stack-headings;
        }
    }
}

.post-actions {
    max-width: 50rem;
    margin: 6rem auto;

    @include breakpoint(XS) {
        margin: 4rem auto;
    }
}

.more-articles {
    padding-top: 4.25rem;
    padding-bottom: 4.25rem;

    @include breakpoint(XS) {
        padding-top: 4.5rem;
        padding-bottom: 3rem;
    }
}

.posts-grid {
    margin-left: -1rem;
    margin-right: -1rem;
    
    &.rel-posts {
        padding-top: 3.5rem;
    }
    .post-item-img {
        display: block;
        height: 25.25rem;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: linear-gradient(360deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%);
            transition: opacity 0.3s ease-in-out;
            opacity: 0;
         }
         
         &:hover::before {
           opacity: 1;
         }

        &:hover{
            .view-article-button{
              display: block;
              position: absolute;
              z-index: 1000;
              bottom: 1rem;
              left: 0;
              right: 0;
              margin: 0 auto;
              width: 100%;
              font-weight: 400;
              text-align: center;
             
              font-family: $font-stack-headings;
              
              color:white;
          
              
            }
          }

        img {
            height: 100%;
            width: 100%;

            @supports (aspect-ratio: initial) {
                aspect-ratio: 4 / 3;
            } 

            @include breakpoint(S) {

                @supports not (aspect-ratio: initial) {
                    height: 10rem;
                } 
            }
            @include breakpoint(XS) {
            
                @supports not (aspect-ratio: initial) {
                    height: 15rem;
                } 
            }
        }
    }
    .post-item {
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 4.5rem;

        @include breakpoint(XS) {
            display: flex;
            margin-bottom: 3rem;
            display: flex;
            flex-direction: column;

            &:last-child {
                margin-bottom: 0;
            }
        }
        &.third {
            width: calc(33.333% - 2rem);
            flex-grow: unset;

            @include breakpoint(S) {
                width: calc(50% - 2rem);
            }
            @include breakpoint(XS) {
                width: 100%;

                .post-item-img {
                    margin-right: 0rem;
                    margin-bottom: 2rem;
                    width:100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        h2,
        h3,
        h4 {
            a {
                @include color(color, 'body'); 

                &:hover {
                    @include color(color, 'accent-primary');
                }
            }
        }
        &.feat-post {
            width: 100%;
            flex-basis: unset;
            flex-direction: row-reverse;
            @include breakpoint(S){
                display: flex;
                flex-direction: column;
            }

            .view-article-button{
                display: none;
            }

            .post-item-img {
                width: 50%;
                position: relative;
                height: 100%;
                min-height: 30rem;

                img{
                    height: 100%;
                }

                @include breakpoint(S) {
                    width: 100%;
                    height: 15rem;
                }
            }
            .feat-post-content {
                width: 50%;
                max-width: 30rem;
                padding-left: 2rem;
                padding-top: 3.5rem;
                padding-bottom: 3.5rem;

                @include breakpoint(S) {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
}

.post-details {
    padding-top: 6rem;
    margin-bottom: 9rem;

    @include breakpoint(XS) {
        padding-top: 4.5rem;
        margin-bottom: 8rem;
    }
    &.thin {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-bottom: 9rem;
        border-bottom: 1px solid;
        @include color(border-color, 'body-10');
    
        @include breakpoint(XS) {
            margin-bottom: 4.5rem;

            .post-share {
                padding-top: 1.5rem;
            }
        }
    }
    &.has-excerpt {
        .post-info {
            min-width: 24rem;
            padding-right: 4.25rem;
            margin-right: 4.25rem;
            border-right: 1px solid;
            @include color(border-color, 'body-10');

            @include breakpoint(M) {
                min-width: 20rem;
            }
            @include breakpoint(XS) {
                min-width: auto;
                width: 100%;
                padding-right: 0;
                margin-right: 0;
                border-right: 0;
                border-bottom: 1px solid;
                @include color(border-color, 'body-10');
                padding-bottom: 3rem;
                margin-bottom: 3rem;
            }
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
    .intro-content {
        p {
            @include size-L;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &.event-details {
        @include breakpoint(XS) {
            padding-top: 2.25rem;
            margin-bottom: 4.5rem;

            .post-info {
                padding-bottom: 2rem;
            }
        }
    }
}

.post-info {
    strong {
        padding-right: 0.5rem;
    }
    a {
        @include color(color, 'body');

        &:hover {
            @include color(color, 'accent-primary');
        }
        &:after {
            content: ",";
            padding-right: 0.25rem;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

.post-share {
    .button {
        margin-left: 1rem;
    }
}

.posts-nav {
    border-top: 1px solid;
    @include color(border-color, 'body-10');
    padding: 3rem 0;

    @include breakpoint(XS) {
        padding: 0;
    }
    a {
        @include color(color, 'body');

        @include breakpoint(XS) {
            padding: 1.5rem 2rem;
            border-bottom: 1px solid;
            @include color(border-color, 'body-10');
        }
        &:hover {
            @include color(color, 'accent-primary');

            .icon {
                @include color(border-color, 'accent-primary');

                svg {
                    @include color(color, 'accent-primary');
                }
            }
        }
        &.prev {
            .icon {
                margin-right: 1.5rem;
            }
            &:hover {
                .icon {
                    transform: translateX(-0.25rem);
                }
            }
        }
        &.next {
            .icon {
                margin-left: 1.5rem;
            }
            &:hover {
                .icon {
                    transform: translateX(0.25rem);
                }
            }
        }
    }
}

.loadmore-wrapper {
    padding-top: 2rem;
}

.offer-share {
    .bordered {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid;
        @include color(border-color, 'body-10');
    }
}

.addeventatc {
    cursor: pointer;
    position: relative;

    @include breakpoint(XS) {
        margin-bottom: 1.5rem;
    }
    &:hover,
    &.addeventatc-selected {
        .atc-button {
            @include color(color, 'accent-primary');
        }
    }
    .atc-button {
        @extend %transition;

        svg {
            margin-left: 0.75rem;
        }
        &:after {
            content: "";
            width: 24px;
            height: 24px;
            background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"%3E%3Ccircle cx="12" cy="12" r="9.5" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/%3E%3Cpath fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 17.5L12 6.5"/%3E%3Cpath fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.5 12L6.5 12"/%3E%3C/svg%3E');
            margin-left: 0.75rem;

            @include breakpoint(XS) {
                width: 21px;
                height: 21px;
                background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24"%3E%3Ccircle cx="12" cy="12" r="9.5" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/%3E%3Cpath fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 17.5L12 6.5"/%3E%3Cpath fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.5 12L6.5 12"/%3E%3C/svg%3E');
            }
        }
    }
    .copyx {
        display: none;
    }
    .addeventatc_dropdown {
        position: absolute;
        @include color(background-color, 'background');
        border: 1px solid;
        @include color(border-color, 'body');
        margin-top: 0.75rem;
        min-width: 14rem;
        display: none;

        span {
            display: block;
            padding: 1.25rem 1rem;
            line-height: 1;
            border-top: 1px solid;
            @include color(border-color, 'body');
            @extend %transition;

            em {
                @extend %transition;
                font-size: 0.75rem;
            }
            &:first-child {
                border-top: 0;
            }
            &:hover {
                @include color(background-color, 'accent-primary');
                @include color(color, 'background');

                em {
                    @include color(color, 'background');
                }
            }
        }
    }
}

.post-feat-img {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}